.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0; /* Or any other background color */
  margin: 0;
}

.centered-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
